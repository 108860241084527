import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'

import Layout from "../components/layout/layout"
import SEOnew from "../components/seo-new"
import CapabilitiesSection from "../components/sections/capabilities-section"
import BenefitsSection from "../components/sections/benefits-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"

const AreasPage = () => {
  const data = useStaticQuery(graphql`
    query AreasPageQuery {      
      allPrismicCityPage {
        edges {
          node {
            id
            uid
            data {
              city_name
            }
          }
        }
      }
    }
  `)

  const cityPages = data.allPrismicCityPage.edges

  return (
    <Layout>
      <SEOnew 
        title="Areas We Serve in Orange County" 
        description="IT Proactive provides excellent IT Service Management and IT Support to clients in the Orange County, CA area."
      />
      
      <div className="bg-white w-full border-b border-t pt-16 pb-14">
        <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-16'>Areas We Serve</h2>
        <div className="w-11/12 max-w-screen-lg grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 mx-auto my-12">          
          {cityPages.map(item => {
            return (
              <Link to={`/${item.node.uid}`}>
                <p className="text-2xl text-center md:text-left text-gray-900 hover:text-red-600 font-semibold mb-2">{item.node.data.city_name}</p>
              </Link>
            )
          })}
        </div>
      </div>

      <BenefitsSection />
      <QuickQuoteSection />
      <CapabilitiesSection />
    </Layout>
  )
}

export default AreasPage